.chat-message {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
}

.chat-message.user {
    justify-content: flex-start;
}

.chat-message.assistant {
    justify-content: flex-end;
    flex-direction: row-reverse;
}

.assistant-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}

.chat-message.assistant .assistant-avatar {
    margin-right: 0;
    margin-left: 10px;
}

.message-content {
    background-color: #f1f1f1;
    padding: 5px;
    border-radius: 3px;
    max-width: 80%;
    word-wrap: break-word;
}

.chat-message.user .message-content {
    text-align: right;
    align-self: flex-end;
}